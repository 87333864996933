// Chakra imports
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import {
    Menu,
    MenuButton,
    MenuList,
    Input,
    MenuItem,
    Button,
    Image,
    ListIcon,
    Flex,
    Tooltip,
    Spinner,
} from "@chakra-ui/react";

import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { ChevronDownIcon, CheckCircleIcon } from "@chakra-ui/icons";
import InputField from "components/fields/InputField";



import { Box } from "@chakra-ui/react";
// Assets
// Custom components
import { useEffect, useState } from "react";
import Config from 'config'
const HOST = Config.Host;


function formatNumber(num) {
    if (num >= 1000000) {
      return Math.floor(num / 1000000) + "mio";
    } else if (num >= 1000) {
      return Math.floor(num / 1000) + "k";
    }
    return num.toString();
  }


export default function LastReferer({customer}) {
    const [tableData, setTableData] = useState(false);
    if(!customer) customer="false";
    useEffect(() => {
        fetch(HOST + "/cc/last_referers?customer=" + customer + "&cors=true", {
            credentials: "include",
            method: "POST",
        })
            .then((r) => r.json())
            .then((j) => {
                setTableData(j)
            })
    }, [customer])
    if (tableData === false) {
        return (
            <>

                <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

                    <Flex
                        my="auto"
                        h="100%"
                        align={{ base: "center", xl: "start" }}
                        justify={{ base: "center", xl: "center" }}
                    >
                        <Spinner size="xl" />
                    </Flex>
                </Box>
            </>
        );
    }
    return (
        <TableContainer>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        {!customer &&
                        <Th>Customer</Th>
            }
                        <Th>Referer</Th>
                        <Th>Amount</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableData.map((element) => {
                        return (
                            <Tr>
                                {!customer &&
                                <Td>{element.parent.id === "000000000000000000000000" ? element.customer.email : element.parent.email}</Td>
                    }
                                <Td><Tooltip label={element._id.referer}><span><span style={{width: 200,display: "block",overflow:"hidden", textOverflow:"elipsis"}}>{element._id.referer}</span></span></Tooltip></Td>
                                <Td>{formatNumber(element.count)}</Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    );
}
