/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Spinner } from '@chakra-ui/react'

import ReactApexChart from "react-apexcharts";
import Card from "components/card/Card";
import {
    Box, Icon, SimpleGrid,
    useColorModeValue, Flex,

} from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import LastDomains from 'components/misc/LastDomains'
import LastSignups from 'components/misc/LastSignups'
import LastValidations from 'components/misc/LastValidations'
import LastReferer from 'components/misc/LastReferer'
import IconBox from "components/icons/IconBox";
import { useEffect, useState } from "react";
import {
    MdOutlineLocalAtm,
    MdMonetizationOn,
    MdMan,
    MdDomainDisabled

} from "react-icons/md";
import ValidationsTable from "./components/ValidationsTable";
import Config from 'config'
const HOST = Config.Host;


export default function UserReports() {
    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const [dashboardData, setDashboardData] = useState(false);
    const [chartData, setChartData] = useState(false);
    useEffect(() => {
        fetch(HOST + "/cc/dashboard?cors=true", {
            credentials: "include",
            method: "POST",
        })
            .then((r) => r.json())
            .then((j) => {
                setDashboardData(j);
                setChartData({
                    series: [
                        {
                            name: j.prev_validation_data.Label,
                            data: j.prev_validation_data.rows,
                        },
                        {
                            name: j.validation_data.Label,
                            data: j.validation_data.rows,
                        }
                    ],
                    options: {
                        chart: {
                            type: "line",
                            toolbar: {
                                show: false,
                            },
                            dropShadow: {
                                enabled: true,
                                top: 13,
                                left: 0,
                                blur: 10,
                                opacity: 0.1,
                                color: "#4318FF",
                            },
                        },
                        colors: ["#39B8FF","#4318FF"],
                        markers: {
                            size: 0,
                            colors: "white",
                            strokeColors: "#7551FF",
                            strokeWidth: 3,
                            strokeOpacity: 0.9,
                            strokeDashArray: 0,
                            fillOpacity: 1,
                            discrete: [],
                            shape: "circle",
                            radius: 2,
                            offsetX: 0,
                            offsetY: 0,
                            showNullDataPoints: true,
                        },
                        tooltip: {
                            theme: "dark",
                            y: {
                                formatter: function (value) {
                                  // Format the x-axis value here (if needed)
                                  if(!value) {
                                    return;
                                  }
                                  return value.toLocaleString('de-DE');
                                },
                            }
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        stroke: {
                            curve: "smooth",
                            type: "line",
                        },
                        xaxis: {
                            type: "datetime",
                            labels: {

                                style: {
                                    colors: "#A3AED0",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                },
                            },

                            axisBorder: {
                                show: false,
                            },
                            axisTicks: {
                                show: false,
                            },
                        },
                        yaxis: {
                            title: {
                                text: "Value",
                            },
                        },
                        legend: {
                            show: false,
                        },
                        grid: {
                            show: false,
                            column: {
                                color: ["#7551FF", "#39B8FF"],
                                opacity: 0.5,
                            },
                        },
                        color: ["#7551FF", "#39B8FF"],
                    },
                })
            });
    }, []);

    if (!dashboardData) {
        return (
            <>
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Flex
        my='auto'
        h='100%'
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}>
                <Spinner   size='xl' />
                </Flex>
            </Box>

            </>

        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
                gap="20px"
                mb="20px"
            >
                <>
                    {dashboardData.cash.months.map((e) => {
                        return (
                            <MiniStatistics
                                startContent={
                                    <IconBox
                                        w="56px"
                                        h="56px"
                                        bg={boxBg}
                                        icon={
                                            <Icon
                                                w="32px"
                                                h="32px"
                                                as={MdOutlineLocalAtm}
                                                color={brandColor}
                                            />
                                        }
                                    />
                                }
                                name={e.label}
                                value={e.value + "€"}
                                growth={e.change}
                            />
                        );
                    })}
                </>

                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdMan}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="Customers this Month"
                    value={dashboardData.this_month.customers.current}
                    growth={dashboardData.this_month.customers.change}
                />

                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdDomainDisabled}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="Domains this Month"
                    value={dashboardData.this_month.domains.current}
                    growth={dashboardData.this_month.domains.change}
                />



            </SimpleGrid>

            <SimpleGrid
                columns={{ base: 1, md: 1, xl: 1 }}
                gap="20px"
                mb="20px"
            >
                <Card
                    justifyContent="center"
                    align="center"
                    direction="column"
                    w="100%"
                    mb="0px"
                >
                    {chartData && chartData.options &&
                    <ValidationsTable chartData={chartData} dashboardData={dashboardData} />
                    }
                </Card>
            </SimpleGrid>
            <SimpleGrid
              columns={{ base: 2, md: 2, xl: 2}}
              gap="20px"
              mb="20px"
              >

                <Card
                    justifyContent="center"
                    align="center"
                    direction="column"
                    w="100%"
                    mb="0px"
                >
                    <font style={{fontSize: "30px", fontWeight: "bold"}}>Validation per Domain (48h)</font>
                    <LastValidations />
                    </Card>


                    <Card
                    justifyContent="center"
                    align="center"
                    direction="column"
                    w="100%"
                    mb="0px"
                >
                    <font style={{fontSize: "30px", fontWeight: "bold"}}>By Referer Last (48h)</font>
                    <LastReferer />
                    </Card>

              </SimpleGrid>
            <SimpleGrid
              columns={{ base: 1, md: 2, xl: 2}}
              gap="20px"
              mb="20px"
              >

                <Card
                    justifyContent="center"
                    align="center"
                    direction="column"
                    w="100%"
                    mb="0px"
                >
                    <font style={{fontSize: "30px", fontWeight: "bold"}}>Last Domains</font>
                    <LastDomains />
                    </Card>
                    <Card
                    justifyContent="center"
                    align="center"
                    direction="column"
                    w="100%"
                    mb="0px"
                >
                    <font style={{fontSize: "30px", fontWeight: "bold"}}>Last Signups</font>
                    <LastSignups />
                    </Card>
              </SimpleGrid>


            {/*
            <SimpleGrid
                columns={{ base: 1, md: 1, xl: 1 }}
                gap="20px"
                mb="20px"
            >
                <Card
                    direction="column"
                    w="100%"
                    px="0px"
                    overflowX={{ sm: "scroll", lg: "hidden" }}
                >
                    <TableContainer>
                        <Table variant="simple" mb="24px">
                            <TableCaption>
                                Imperial to metric conversion factors
                            </TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>To convert</Th>
                                    <Th>into</Th>
                                    <Th isNumeric>multiply by</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>inches</Td>
                                    <Td>millimetres (mm)</Td>
                                    <Td isNumeric>25.4</Td>
                                </Tr>
                                <Tr>
                                    <Td>feet</Td>
                                    <Td>centimetres (cm)</Td>
                                    <Td isNumeric>30.48</Td>
                                </Tr>
                                <Tr>
                                    <Td>yards</Td>
                                    <Td>metres (m)</Td>
                                    <Td isNumeric>0.91444</Td>
                                </Tr>
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th>To convert</Th>
                                    <Th>into</Th>
                                    <Th isNumeric>multiply by</Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </TableContainer>
                </Card>
            </SimpleGrid>
                */}
        </Box>
    );
}
