import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";

export default function ColoredNumber({nr, icon, fontSize}) {
      var color = 'green.500';
      var ic = RiArrowUpSFill
      if (nr < 0 ) {
         color = 'red.500';
         ic = RiArrowDownSFill
      }
      var pr = "";
      if(nr > 0) pr = "+";
      if(!fontSize) {
        fontSize = "sm";
      }
      return (
           <>
            {icon && <Icon as={ic} color={color} me='2px' mt='2px' />}

           <Text color={color} fontSize={fontSize} fontWeight='700'>
                {pr}{nr.toFixed(2)}%
              </Text>
            </>
      )
}
