// Chakra imports
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    TableContainer,
} from "@chakra-ui/react";
import {
    Menu,
    MenuButton,
    Code,
    MenuList,
    Input,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    Checkbox,
    FormLabel,
    NumberInputField,
    FormControl,
    NumberInput,
    Divider,
    Heading,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInputStepper,
    VStack,
    TabPanel,
    Tooltip,
    MenuItem,
    Icon,
    Button,
    Image,
    ListIcon,
    Modal,
    ModalOverlay,
    Select,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Flex,
    Spinner,
    ModalCloseButton,
    SimpleGrid,
} from "@chakra-ui/react";

import {
    RiArrowDownSFill,
    RiArrowUpSFill,
    RiInfinityFill,
} from "react-icons/ri";
import { ChevronDownIcon, CheckCircleIcon, UpDownIcon } from "@chakra-ui/icons";
import { MdWarning, MdNumbers, MdShield, MdZoomIn } from "react-icons/md";
import InputField from "components/fields/InputField";

import { Box } from "@chakra-ui/react";
// Assets
// Custom components
import { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import LastReferer from "components/misc/LastReferer";

import SaveAble from "components/misc/SaveAble";


import Config from "config";
const HOST = Config.Host;

function Algos({ id, data }) {
  if (!data.V2Config) {
    data.V2Config = {
      workers: 5,
      zeros: 3,
      minChallenges: 3,
      minDuration: 100,
      enabled: false,
      checksTime: true
    };
  }
  console.log(data)
  const [values, setValues] = useState({
    v1: {
      minDuration: data.minDuration,
      difficulty: data.difficulty,
      zeros: data.zeros,
      enabled: data.V2Config.enabled ===  false ? true : false
    },
    v2: {
      workers: data.V2Config.workers,
      minChallenges: data.V2Config.minChallenges,
      checksTime: data.V2Config.checksTime,
      zeros: data.V2Config.zeros,
      minDuration: data.V2Config.minDuration,
      enabled: data.V2Config.enabled
    }
  });
  const [saving, setSaving] = useState(false);

  const [enabledVersion, setEnabledVersion] = useState(data.V2Config.enabled ===  true ? "v2" : "v1");  // 'v1' or 'v2'

  const handleChange = (version, field, value) => {
    setValues(prev => ({
      ...prev,
      [version]: {
        ...prev[version],
        [field]: parseFloat(value) || value === 0 ? parseFloat(value) : value
      }
    }));
  };

  const handleVersionChange = (event) => {
    const version = event.target.value;
    setEnabledVersion(version);
    // Disable the not-selected version and enable the selected one
    setValues(prev => ({
      ...prev,
      v1: { ...prev.v1, enabled: version === 'v1' },
      v2: { ...prev.v2, enabled: version === 'v2' }
    }));
  };

  const saveAllSettings = () => {
    console.log("Saving all settings:", values);
    setSaving(true)
    // Integration with a backend API could be implemented here
    fetch(HOST+'/cc/customers/' + id + '/save_pow?cors=true', { method: 'POST', body: JSON.stringify(values), credentials: "include" }).then(() => {
        setSaving(false);
    })


  };

  return (
    <VStack spacing={6} align="stretch">
      <Heading size="md">Version to use</Heading>
      <Select placeholder="Select version" value={enabledVersion} onChange={handleVersionChange}>
        <option value="v1">Version 1</option>
        <option value="v2">Version 2</option>
      </Select>

      <Box>
        <Heading size="md">Version 1</Heading>
        <FormControl mt={4} isDisabled={!values.v1.enabled}>
          <FormLabel>Min Duration (milliseconds)</FormLabel>
          <NumberInput value={values.v1.minDuration} onChange={(val) => handleChange('v1', 'minDuration', val)} min={0}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isDisabled={!values.v1.enabled}>
          <FormLabel>Difficulty</FormLabel>
          <NumberInput value={values.v1.difficulty} onChange={(val) => handleChange('v1', 'difficulty', val)} min={0}>
            <NumberInputField />
          </NumberInput>
        </FormControl>
        <FormControl isDisabled={!values.v1.enabled}>
          <FormLabel>Zeros</FormLabel>
          <NumberInput value={values.v1.zeros} onChange={(val) => handleChange('v1', 'zeros', val)} min={0}>
            <NumberInputField />
          </NumberInput>
        </FormControl>
      </Box>

      <Divider my={6} />

      <Box>
        <Heading size="md">Version 2</Heading>
        <FormControl mt={4} isDisabled={!values.v2.enabled}>
          <FormLabel>Workers</FormLabel>
          <NumberInput value={values.v2.workers} onChange={(val) => handleChange('v2', 'workers', val)} min={0}>
            <NumberInputField />
          </NumberInput>
        </FormControl>
        <FormControl isDisabled={!values.v2.enabled}>
          <FormLabel>Min Challenges</FormLabel>
          <NumberInput value={values.v2.minChallenges} onChange={(val) => handleChange('v2', 'minChallenges', val)} min={0}>
            <NumberInputField />
          </NumberInput>
        </FormControl>
        <FormControl isDisabled={!values.v2.enabled}>
          <FormLabel>Checks Time</FormLabel>
          <Checkbox isChecked={values.v2.checksTime} onChange={(e) => handleChange('v2', 'checksTime', e.target.checked)}>
            Checks Time
          </Checkbox>
        </FormControl>
        <FormControl isDisabled={!values.v2.enabled}>
          <FormLabel>Zeros</FormLabel>
          <NumberInput value={values.v2.zeros} onChange={(val) => handleChange('v2', 'zeros', val)} min={0}>
            <NumberInputField />
          </NumberInput>
        </FormControl>
        <FormControl isDisabled={!values.v2.enabled}>
          <FormLabel>Min Duration (milliseconds)</FormLabel>
          <NumberInput value={values.v2.minDuration} onChange={(val) => handleChange('v2', 'minDuration', val)} min={0}>
            <NumberInputField />
          </NumberInput>
        </FormControl>
      </Box>

      <Button mt={4} colorScheme="teal" disabled={saving} variant="outline" onClick={saveAllSettings}>Save Settings</Button>
    </VStack>
  );
}

export default function CustomerDetail({ id }) {
    const [data, setData] = useState(false);
    const [localId, setLocalId] = useState(id);
    const [domains, setDomains] = useState([]);

    useEffect(() => {
        fetch(HOST + "/cc/customers/" + localId + "?cors=true", {
            credentials: "include",
            method: "POST",
        })
            .then((r) => r.json())
            .then((j) => {
                setData(j.customer);
                setDomains(j.domains);
            });
    }, [id, localId]);
    if (!data) {
        return (
            <>
                <Flex
                    my="auto"
                    h="100%"
                    align={{ base: "center", xl: "start" }}
                    justify={{ base: "center", xl: "center" }}
                >
                    <Spinner size="xl" />
                </Flex>
            </>
        );
    }
    var mainFields = [
        {
            l: "ID",
            v: data.id,
        },
        {
            l: "Created",
            v: data.created_at,
        },
        {
            l: "Complexity",
            v: "Diff: " + data.difficulty + ", Zeros: " + data.zeros,
        },
        {
            l: "Name",
            v: data.name === "" ? "N/A" : data.name,
        },
        {
            l: "Email",
            v: data.email === "" ? "N/A" : data.email,
        },
        {
            l: "Hide Shield",
            v: data.hideShield ? "true" : "false",
        },
        {
            l: "Check Origin",
            v: <SaveAble   value={data.checksOrigin} customerID={localId} fkey="checksOrigin" type="bool" />,
        },
        {
            l: "Exclude from ML",
            v: <SaveAble   value={data.skipML} customerID={localId} fkey="skipML" type="bool" />,
        },
        {
            l: "Allows Subdomains",
            v: <SaveAble   value={data.allowsSubdomains} customerID={localId} fkey="allowsSubdomains" type="bool" />,
        },
        {
            l: "Is Enterprise",
            v: data.isEnterprise ? "true" : "false",
        },
        {
            l: "Parent or Domain",
            v: data.isSub ? (
                <>
                    <Button
                        onClick={() => {
                            setLocalId(data.parent);
                        }}
                    >
                        {data.parent}
                    </Button>
                </>
            ) : (
                "false"
            ),
        },
        {
            l: "Validations",
            v:
                "Hard: " +
                data.hardValidations +
                " Sub: " +
                data.validationsLeft +
                " Burst: " +
                data.burstLeft,
        },
        {
            l: "Over Usage",
            v: "" + data.overUsage,
        },
        {
            l: "DomainLimit",
            v:  <SaveAble   value={data.domainsLimit} customerID={localId} fkey="domainsLimit" type="integer" />,
        },
        {
            l: "Hard Validations",
            v:  <SaveAble   value={data.hardValidations} customerID={localId} fkey="hardValidations" type="integer" />,
        },
        {
            l: "Ultimate till: ",
            v:
                data.infiniteTill !== "0001-01-01T00:00:00Z"
                    ? data.infiniteTill
                    : "N/A",
        },
        {
            l: "Login Token",
            v: <Code colorScheme="orange">{data.loginToken}</Code>,
        },
        {
            l: "Last Login: ",
            v:
                data.lastLogin === "0001-01-01T00:00:00Z"
                    ? "Never"
                    : data.lastLogin,
        },
    ];
    return (
        <div id={localId}>
            <Tabs >
                <TabList>
                    <Tab>Main</Tab>
                    {!data.isSub && <Tab>Plan</Tab>}
                    {data.isSub && <Tab>Calls</Tab>}
                    {!data.isSub && <Tab>Domains</Tab>}
                    {data.isSub && <Tab>PoW</Tab>}
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <table >
                            {mainFields.map((e) => {
                                return (
                                    <tr>
                                        <td  style={{padding: 10    }}>
                                            <b>{e.l}: </b>
                                        </td>
                                        <td>{e.v}</td>
                                    </tr>
                                );
                            })}
                        </table>
                    </TabPanel>
                    {!data.isSub && (
                        <TabPanel>
                            <p>
                                <b>Name</b>: {data.plan.name}
                            </p>
                            <p>
                                <b>Period</b>: {data.plan.period.start} -{" "}
                                {data.plan.period.end}
                            </p>
                            <p>
                                <b>Validations</b>: {data.plan.validations}
                            </p>
                            <p>
                                <b>Domains</b>: {data.plan.domains}
                            </p>
                            <p>
                                <b>Kind</b>: {data.plan.kind}
                            </p>
                        </TabPanel>
                    )}
                    {data.isSub && (
                        <TabPanel>
                            <LastReferer customer={localId} />
                        </TabPanel>
                    )}
                    {!data.isSub && (
                        <TabPanel>
                            Domains:
                            {domains.map((d) => {
                                return (
                                    <Card
                                        align="left"
                                        direction="column"
                                        w="100%"
                                        maxW="max-content"
                                        p="20px 15px"
                                        h="max-content"
                                    >
                                        <Button
                                            onClick={() => {
                                                setLocalId(d.id);
                                            }}
                                        >
                                            {d.name}
                                        </Button>
                                        <span>
                                            <b>Rest Key</b>:{" "}
                                            <Code colorScheme="orange">
                                                {d.restKey}
                                            </Code>
                                        </span>
                                        <span>
                                            <b>Public Key</b>:{" "}
                                            <Code colorScheme="orange">
                                                {d.publicSecret}
                                            </Code>
                                        </span>
                                    </Card>
                                );
                            })}
                        </TabPanel>

                    )}
                    {data.isSub &&
                    <TabPanel>
                       <Algos id={id} data={data} />
                    </TabPanel>
                    }
                </TabPanels>
            </Tabs>
        </div>
    );
}
