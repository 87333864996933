// Chakra imports
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import {
    Menu,
    MenuButton,
    MenuList,
    Input,
    MenuItem,
    Button,
    Image,
    ListIcon,
    Flex,
    Spinner,
} from "@chakra-ui/react";

import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { ChevronDownIcon, CheckCircleIcon } from "@chakra-ui/icons";
import InputField from "components/fields/InputField";



import { Box } from "@chakra-ui/react";
// Assets
// Custom components
import { useEffect, useState } from "react";
import Config from 'config'
const HOST = Config.Host;

export default function LastSignups() {
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        fetch(HOST + "/cc/last_signups?cors=true", {
            credentials: "include",
            method: "POST",
        })
            .then((r) => r.json())
            .then((j) => {
                setTableData(j)
            })
    }, [])
    if (tableData.length == 0) {
        return (
            <>

                <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

                    <Flex
                        my="auto"
                        h="100%"
                        align={{ base: "center", xl: "start" }}
                        justify={{ base: "center", xl: "center" }}
                    >
                        <Spinner size="xl" />
                    </Flex>
                </Box>
            </>
        );
    }
    return (
        <TableContainer>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Created At</Th>
                        <Th>Name</Th>
                        <Th>Email</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableData.map((domain) => {
                        return (
                            <Tr>
                                <Td>{(new Date(domain.created_at)).toISOString().replace('T', ' ').slice(0, 16)}</Td>
                                <Td>{domain.name}</Td>
                                <Td>{domain.email}</Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    );
}
