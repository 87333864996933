// Chakra imports
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    TableContainer,
} from "@chakra-ui/react";
import {
    Menu,
    MenuButton,
    Code,
    MenuList,
    Input,
    Tabs,
    TabList,
    Switch,
    TabPanels,
    Tab,
    TabPanel,
    Tooltip,
    MenuItem,
    Icon,
    Button,
    Image,
    ListIcon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Flex,
    Spinner,
    ModalCloseButton,
    SimpleGrid,
} from "@chakra-ui/react";

import {
    RiArrowDownSFill,
    RiArrowUpSFill,
    RiInfinityFill,
} from "react-icons/ri";
import { ChevronDownIcon, CheckCircleIcon, UpDownIcon } from "@chakra-ui/icons";
import { MdWarning, MdNumbers, MdShield, MdZoomIn } from "react-icons/md";
import InputField from "components/fields/InputField";

import { Box } from "@chakra-ui/react";
// Assets
// Custom components
import { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import LastReferer from "components/misc/LastReferer";

import Config from "config";
const HOST = Config.Host;

export default function SaveAble({ type, value, fkey,customerID, ...rest }) {
    const [myValue, setMyValue] = useState(value)
    const [disabled, setDisabled] = useState(false)



    function saveIt() {
        setDisabled(true);
        var payload = {
            value: myValue,
        }
        fetch(HOST + "/cc/customers/" + customerID + "/key/" + fkey + "?cors=true", {
            credentials: "include",
            body: JSON.stringify(payload),
            method: "POST",
        })
            .then((r) => r.json())
            .then((j) => {

               setDisabled(false);
            });
        console.log("SAVE: ", fkey, type, myValue)
    }




    return (
        <SimpleGrid columns={2} spacing={2}>
            {type === "integer" &&
                <Input placeholder='' size='lg' value={myValue} onChange={(e) => {
                    setMyValue(parseInt(e.target.value))
                }}/>
            }
            {type === "bool" &&
                 <Switch size='xl' isChecked={myValue} onChange={(e) => {

                    //console.log("XXX", e.target.value ? false : true, e.isChecked)
                    setMyValue(!myValue)
                }} />
            }
            <Button disabled={disabled} width={20} colorScheme="teal" variant="outline" onClick={() => {
                saveIt();
            }}>
                Save
            </Button>
        </SimpleGrid>
    );
}
