// Chakra imports
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    TableContainer,
} from "@chakra-ui/react";
import {
    Menu,
    MenuButton,
    MenuList,
    Input,
    Text,
    Tooltip,
    MenuItem,
    Icon,
    Button,
    Image,
    ListIcon,
    Modal,
    MenuDivider,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
} from "@chakra-ui/react";

import {
    RiArrowDownSFill,
    RiArrowUpSFill,
    RiInfinityFill,
} from "react-icons/ri";
import { ChevronDownIcon, CheckCircleIcon, UpDownIcon } from "@chakra-ui/icons";
import { MdWarning, MdNumbers, MdShield, MdZoomIn } from "react-icons/md";
import InputField from "components/fields/InputField";
import CustomerDetail from "components/misc/CustomerDetail";
import TrialDialog from "components/misc/TrialDialog";
import WireCardDialog from "components/misc/WireCardDialog";
import { Box } from "@chakra-ui/react";
// Assets
// Custom components
import { useEffect, useState } from "react";
import Card from "components/card/Card.js";

import Config from "config";
const HOST = Config.Host;
function formatNumber(num) {
    if (num >= 1000000) {
        return Math.floor(num / 1000000) + "mio";
    } else if (num >= 1000) {
        return Math.floor(num / 1000) + "k";
    }
    return num.toString();
}

const Countdown = ({ targetDate }) => {
    // Calculate the number of days left
    const currentDate = new Date();
    const timeDifference = targetDate - currentDate;
    const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Create a human-readable message
    let message;
    if (daysLeft === 0) {
      message = 'Last Day';
    } else if (daysLeft === 1) {
      message = '1 day left';
    } else {
      message = `${daysLeft} days left`;
    }

    return <Text fontSize="xl">{message}</Text>;
  };

export default function UserReports({ trials, overUsage, domains }) {
    // Chakra Color Mode
    const [tableData, setTableData] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(false);
    const [trial, setTrial] = useState({ open: false, customer: false });
    const [wirecard, setWirecard] = useState({ open: false, customer: false });

    var trls = "";

    function closeModal() {
        setIsOpen(false);
    }
    function showDetail(id) {
        setIsOpen(true);
        setSelected(id);
    }
    function loadData() {
        var payload = {
            s: searchTerm,
            trials: trials,
            overUsage: overUsage,
            domains: domains,
        };
        fetch(HOST + "/cc/customers/list?" + trls + "&cors=true", {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(payload),
        })
            .then((r) => r.json())
            .then((j) => {
                setTableData(j);
            });
    }
    function resetOverUsage(customer) {
        var payload = {
        };
        fetch(HOST + "/cc/customers/" + customer._id + "/reset_overusage?cors=true", {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(payload),
        })
            .then((r) => r.json())
            .then((j) => {
                loadData();
            });
    }
    function setShield(customer, st) {
        var payload = {
            status: st,
        };
        fetch(HOST + "/cc/customers/" + customer._id + "/shield?cors=true", {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(payload),
        })
            .then((r) => r.json())
            .then((j) => {
                loadData();
            });
    }
    useEffect(() => {
        loadData();
    }, [searchTerm]);

    return (
        <>
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <InputField
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                />
                {tableData && (
                    <Card py="15px">
                        <TableContainer>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                    {domains && (
                                        <Th>Domain</Th>
                                    )}
                                        <Th>Customer</Th>
                                        <Th>Flags</Th>
                                        {trials && <Th>Trial End</Th>}
                                        {overUsage && <Th>Over</Th>}
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {tableData.map((customer) => {
                                        var infIsActive = false;
                                        if(customer.inifniteTill) {
                                            var f = new Date(customer.inifniteTill)
                                            if(f > new Date()) {
                                                infIsActive = true
                                            }
                                        }
                                        return (
                                            <Tr>
                                                {customer.isSub && (
                                                <Td>

                                                        <>{customer.name}</>


                                                </Td>
                                                )}
                                                <Td>
                                                    {customer.isSub && (
                                                        <>{customer.resolved.email}</>
                                                    )}
                                                    {!customer.isSub && (
                                                        <>{customer.email}</>
                                                    )}
                                                </Td>
                                                <Td>
                                                    <SimpleGrid
                                                        columns={{
                                                            base: 4,
                                                            md: 4,
                                                            xl: 4,
                                                        }}
                                                        gap="3px"
                                                        mb="2px"
                                                    >
                                                        <Tooltip label="Details">
                                                            <span>
                                                                <Icon
                                                                    onClick={() =>
                                                                        showDetail(
                                                                            customer._id
                                                                        )
                                                                    }
                                                                    boxSize={6}
                                                                    as={
                                                                        MdZoomIn
                                                                    }
                                                                    color="green.500"
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                        {customer.hideShield && (
                                                            <Tooltip label="Shield hidden">
                                                                <span>
                                                                    <Icon
                                                                        boxSize={
                                                                            6
                                                                        }
                                                                        as={
                                                                            MdShield
                                                                        }
                                                                        color="green.500"
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        )}
                                                        {!customer.hideShield && (
                                                            <Tooltip label="Shield visible">
                                                                <span>
                                                                    <Icon
                                                                        boxSize={
                                                                            6
                                                                        }
                                                                        as={
                                                                            MdShield
                                                                        }
                                                                        color="red.500"
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        )}
                                                        {(customer.isEnterprise && infIsActive) && (
                                                            <Tooltip
                                                                label={
                                                                    "Unlimited: " +
                                                                    customer.inifniteTill
                                                                }
                                                            >
                                                                <span>
                                                                    <Icon
                                                                        boxSize={
                                                                            6
                                                                        }
                                                                        as={
                                                                            RiInfinityFill
                                                                        }
                                                                        color="blue.500"
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        )}
                                                        {!customer.isEnterprise &&
                                                            !customer.isSub &&
                                                            (
                                                                <Tooltip
                                                                    label={"Validations Left (Hard: " + customer.hardValidations + " Burst:" + customer.burstLeft + " Plan:" + customer.validationsLeft}
                                                                >
                                                                    <span>
                                                                    {
                                                                        "" +
                                                                        formatNumber(customer.validationsLeft +
                                                                            customer.hardValidations +
                                                                            customer.burstLeft)
                                                                    }
                                                                    </span>
                                                                </Tooltip>
                                                            )}
                                                        {customer.overUsage >
                                                            0 && (
                                                            <Tooltip
                                                                label={
                                                                    "Over Usage: " +
                                                                    customer.overUsage
                                                                }
                                                            >
                                                                <span>
                                                                    <Icon
                                                                        boxSize={
                                                                            6
                                                                        }
                                                                        color="orange.500"
                                                                        as={
                                                                            MdWarning
                                                                        }
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        )}
                                                    </SimpleGrid>
                                                </Td>
                                                {(trials || overUsage) &&
                                                <Td>
                                                {trials && (
                                                    <Tooltip label={customer.inifniteTill}>
                                                        <span><Countdown targetDate={new Date(customer.inifniteTill)} /> </span>
                                                    </Tooltip>
                                                )}
                                                {overUsage && (
                                                    <>
                                                        {formatNumber(
                                                            customer.overUsage
                                                        )}
                                                    </>
                                                )}
                                                </Td>
                                    }
                                                <Td>
                                                    <Menu>
                                                        <MenuButton
                                                            as={Button}
                                                            rightIcon={
                                                                <ChevronDownIcon />
                                                            }
                                                        >
                                                            Actions
                                                        </MenuButton>
                                                        <MenuList>
                                                            <MenuItem
                                                                minH="40px"
                                                                onClick={() =>
                                                                    setShield(
                                                                        customer,
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <span>
                                                                    Enable
                                                                    Shield
                                                                </span>
                                                            </MenuItem>
                                                            <MenuItem
                                                                minH="40px"
                                                                onClick={() =>
                                                                    setShield(
                                                                        customer,
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                <span>
                                                                    Disable
                                                                    Shield
                                                                </span>
                                                            </MenuItem>

                                                            <MenuDivider />
                                                            {!customer.isSub &&
                                                            <>
                                                            <MenuItem
                                                                minH="40px"
                                                                onClick={() =>
                                                                    setTrial({
                                                                        open: true,
                                                                        customer:
                                                                            customer,
                                                                    })
                                                                }
                                                            >
                                                                <span>
                                                                    Manage Trial
                                                                </span>
                                                            </MenuItem>
                                                            <MenuItem
                                                                minH="40px"
                                                                onClick={() =>
                                                                    setWirecard({
                                                                        open: true,
                                                                        customer:
                                                                            customer,
                                                                    })
                                                                }
                                                            >
                                                                <span>
                                                                    Banktransfer
                                                                </span>
                                                            </MenuItem>
                                                            </>


                                                        }
                                                        <MenuItem
                                                                minH="40px"
                                                                onClick={() =>
                                                                    resetOverUsage(customer)
                                                                }
                                                            >
                                                                <span>Reset OverUsage</span>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Card>
                )}
            </Box>

            <TrialDialog
                config={trial}
                onClose={() => {
                    setTrial({ open: false, customer: false });
                }}
            />
            <WireCardDialog
                config={wirecard}
                onClose={() => {
                    setWirecard({ open: false, customer: false });
                }}
            />

            <Modal isOpen={isOpen} onClose={closeModal} size="xl">
                <ModalOverlay
                    bg="blackAlpha.300"
                    backdropFilter="blur(10px) hue-rotate(90deg)"
                />
                <ModalContent>
                    <ModalHeader>Customer Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <CustomerDetail id={selected} />
                    </ModalBody>

                    {/**
          <ModalFooter>
            <Button variantColor="blue" mr={3} onClick={closeModal}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter>
          */}
                </ModalContent>
            </Modal>
        </>
    );
}
