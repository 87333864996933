// Chakra imports
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    TableContainer,
} from "@chakra-ui/react";
import {
    Menu,
    MenuButton,
    Code,
    MenuList,
    Input,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Tooltip,
    MenuItem,
    Icon,
    Button,
    Image,
    ListIcon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Flex,
    Spinner,
    ModalCloseButton,
    SimpleGrid,
} from "@chakra-ui/react";

import {
    RiArrowDownSFill,
    RiArrowUpSFill,
    RiInfinityFill,
} from "react-icons/ri";
import { ChevronDownIcon, CheckCircleIcon, UpDownIcon } from "@chakra-ui/icons";
import { MdWarning, MdNumbers, MdShield, MdZoomIn } from "react-icons/md";
import InputField from "components/fields/InputField";

import { Box } from "@chakra-ui/react";
// Assets
// Custom components
import { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import LastReferer from "components/misc/LastReferer";
import MiniCalendar from "components/calendar/MiniCalendar";

import Config from "config";
const HOST = Config.Host;

export default function TrialDialog({ config, onClose }) {
    const [newDate, setNewDate] = useState(false);
    var trialActive = true;
    if (
        config.customer.inifniteTill &&
        config.customer.inifniteTill.match(/^0001-01-01T/)
    ) {
        trialActive = false;
    }
    if (new Date(config.customer.inifniteTill) < new Date()) {
        trialActive = false;
    }
    // Selected Date
    var currDate = new Date();

    if (trialActive) {
        currDate = new Date(config.customer.inifniteTill);
    }

    return (
        <>
            <Modal isOpen={config.open} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Trial Config</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <b>Manage Trial for:</b>
                        <br />
                        {config.customer.email} <br />
                        {trialActive && (
                            <font color="red">
                                Trial Active till:{" "}
                                {config.customer.inifniteTill}
                            </font>
                        )}
                        <MiniCalendar
                            current={currDate}
                            selected={(d) => {
                                setNewDate(d);
                            }}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            colorScheme="blue"
                            onClick={() => {
                                var payload = {
                                    ts: Math.ceil(newDate.getTime() / 1000),
                                    customer: config.customer._id,
                                };
                                fetch(
                                    HOST +
                                        "/cc/customers/" +
                                        config.customer._id +
                                        "/trial?cors=true",
                                    {
                                        credentials: "include",
                                        method: "POST",
                                        body: JSON.stringify(payload)
                                    }
                                )
                                    .then((r) => r.json())
                                    .then((j) => {
                                        onClose()
                                    });
                            }}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
