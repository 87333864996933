// Chakra imports
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    TableContainer,
} from "@chakra-ui/react";
import {
    Menu,
    MenuButton,
    Code,
    MenuList,
    Input,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    Checkbox,
    TabPanel,
    Tooltip,
    MenuItem,
    Icon,
    FormControl,
    FormLabel,
    Button,
    Image,
    ListIcon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Flex,
    Spinner,
    ModalCloseButton,
    SimpleGrid,
} from "@chakra-ui/react";

import {
    RiArrowDownSFill,
    RiArrowUpSFill,
    RiInfinityFill,
} from "react-icons/ri";
import { ChevronDownIcon, CheckCircleIcon, UpDownIcon } from "@chakra-ui/icons";
import { MdWarning, MdNumbers, MdShield, MdZoomIn } from "react-icons/md";
import InputField from "components/fields/InputField";

import { Box } from "@chakra-ui/react";
// Assets
// Custom components
import { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import LastReferer from "components/misc/LastReferer";
import MiniCalendar from "components/calendar/MiniCalendar";

import Config from "config";
const HOST = Config.Host;

export default function WireCardDialog({ config, onClose }) {
    const [newDate, setNewDate] = useState(new Date());
    const [newDate1, setNewDate1] = useState(new Date());
    const [priceID, setPriceID] = useState("");
    const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
    var trialActive = true;
    if (
        config.customer.inifniteTill &&
        config.customer.inifniteTill.match(/^0001-01-01T/)
    ) {
        trialActive = false;
    }
    if (new Date(config.customer.inifniteTill) < new Date()) {
        trialActive = false;
    }
    // Selected Date
    var currDate = new Date();

    if (trialActive) {
        currDate = new Date(config.customer.inifniteTill);
    }

    const handleChangePriceId = (event) => {
        setPriceID(event.target.value);
        // You can add additional logic here, such as validation
    };

    return (
        <>
            <Modal isOpen={config.open} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Enable Bank Transfer</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <b>Bank Transfer for:</b>
                        <br />
                        {config.customer.email} <br />
                        <FormControl>
                            <FormLabel htmlFor="textInput">Price ID</FormLabel>
                            <Input
                                id="priceID"
                                placeholder="Price ID - starts with price_"
                                value={priceID}
                                onChange={handleChangePriceId}
                            />
                        </FormControl>
                        <Checkbox
      isChecked={isChecked}
      onChange={handleCheckboxChange}
      colorScheme="blue" // You can change the color scheme
    >
      Manual Start Conditions
    </Checkbox>
    {isChecked &&
                        <SimpleGrid columns={2} spacing={10}>
                            <Box>
                                <b>Start Date</b>
                                <MiniCalendar
                                    current={currDate}
                                    selected={(d) => {
                                        setNewDate(d);
                                    }}
                                />
                            </Box>
                            <Box>
                                <b>Billing Anchor</b>
                                <MiniCalendar
                                    current={currDate}
                                    selected={(d) => {
                                        setNewDate1(d);
                                    }}
                                />
                            </Box>
                        </SimpleGrid>
}
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            colorScheme="blue"
                            onClick={() => {
                                var payload = {
                                    start: Math.ceil(newDate.getTime() / 1000),
                                    anchor: Math.ceil(newDate1.getTime() / 1000),
                                    customer: config.customer._id,
                                    price: priceID,
                                    manual: isChecked,
                                };
                                fetch(HOST + "/cc/customers/" + config.customer._id + "/banktransfer?cors=true", {
                                    credentials: "include",
                                    method: "POST",
                                    body: JSON.stringify(payload),
                                })
                                .then((response) => {
                                    if (!response.ok) {
                                        throw new Error('Network response was not ok: ' + response.statusText);
                                    }
                                    return response.json();
                                })
                                .then((jsonData) => {
                                    onClose();
                                })
                                .catch((error) => {
                                    alert(JSON.stringify(error));
                                });
                            }}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
